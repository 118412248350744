export const SPECS = {
  CashierExpressNavigateToThankYouPageWhen1ShippingMethod:
    'specs.stores.CashierExpressNavigateToThankYouPageWhen1ShippingMethod',
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  ApplePayDebugNullOrderId: 'specs.stores.ApplePayDebugNullOrderId',
  FastFlowCustomField: 'specs.stores.FastFlowCustomField',
  ExtraMarginMobileCart: 'specs.stores.ExtraMarginMobileCart',
  SeparatePaymentsStyleCart: 'specs.stores.SeparatePaymentsStyleCart',
  EXPRESS_CASHIER_BI_FIX: 'specs.stores.ExpressCashierBiFix',
} as const;
